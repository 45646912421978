

import { Component, Watch, Prop } from 'vue-property-decorator';
import ImportComponent from '../components/ImportComponent.vue';
import ExpenseHelper from '../components/ExpenseHelper';
import { CurrentUser, Expense, ShortRecord, Vendor } from '../store/models';

@Component
export default class ImportExpense extends ImportComponent {

  @Prop() private projectId: any;

  private helper: ExpenseHelper = new ExpenseHelper();

  protected isFieldsLoaded(): boolean {
    return (!this.helper.isLoading);
  }

  protected mounted() {
    this.loadSchemas();
    this.helper.populateDataLists(this.currentOrg);
  }

  protected getConvertedJsonRecord(record: any): Expense|null {
    const payee: ShortRecord|null = this.helper.getPayee(record.hasPayee);
    const isPayeeIndigenous: boolean = this.isPayeeIndigenousCommunity(payee);
    const expenseClass: string = this.getExpenseClass(payee ? payee.defaultExpenseClass : '', record.expenseClass, isPayeeIndigenous);
    const expenseType: string = this.getExpenseType(expenseClass, record.hasProject, record.hasPayee, isPayeeIndigenous);
    // const expenseKind: string = this.getExpenseKind(expenseClass, record.expenseKind);
    const expenseKind: string = 'cash';
    const expense: Expense|null =
    payee ?
    {
      expense: {
        expenseClass,
        expenseType,
        expenseKind,
        expenseId: 'N/A',
      },
      revenue: {
        revenueClass: expenseClass, // Same as Expense Class.
        revenueType: this.helper.getRevenueType(expenseType),
        revenueKind: expenseKind, // Same as Expense Kind.
        revenueId: 'N/A',
      },
      payment: {
        amount: this.cleanseCurrencyField(record.amount),
        currency: 'CAD',
        effectiveDate: this.toIsoDateString(record.effectiveDate),
        hasPayer: this.$store.getters['local/CURRENT_ORG'],
        hasSubmitter: (this.$store.getters['session/CURRENT_USER'] as CurrentUser).userinfo.identifier,
        description: record.description,
        hasPayee: record.hasPayee,
        hasProject: record.hasProject,
      },
    } : null;
    // console.log(JSON.stringify(expense));
    return expense;
  }

  protected getSchemas(): string[] {
    return [ 'general', 'create' ];
  }

  protected getFields(): any[] {
    const fields =
    [
      this.getField('description', 'Description'),
      this.getField('amount', 'Amount'),
      this.getField('hasPayee', 'Payee', this.getOptionsFromShortRecords(this.helper.payees())),
      this.getField('hasProject', 'Project', this.getOptionsFromShortRecords(this.helper.projects)),
      this.getField('effectiveDate', 'Date'),
      // this.getField('expenseKind', 'Expense Kind', this.getOptionsFromRefData(this.helper.expenseKindsAll)),
    ];
    return fields;
  }

  protected getType(): string {
    return 'Expense';
  }

  protected getSchemaType(): string {
    return 'expense';
  }

  @Watch('helper.isLoading')
  private onHelperIsLoadingChanged(val: any, oldVal: any) {
    this.launchIfLoaded();
  }

  private getExpenseClass(defaultExpenseClass: string|null|undefined, recordExpenseClass: string|null, isPayeeIndigenous: boolean): string {
    return isPayeeIndigenous
      ? 'contribution'
      : (recordExpenseClass && recordExpenseClass !== '')
        ? recordExpenseClass
        : defaultExpenseClass ? defaultExpenseClass : '';
  }

  private getExpenseType(expenseClass: string, hasProject: string|null, hasPayee: string|null, isPayeeIndigenous: boolean): string {
    switch (expenseClass) {
      case 'services':
      case 'goods':
      case 'equipment':
        return (hasProject && hasProject !== '') ? 'project-expense' : 'general-expense';
      case 'contribution':
        return (hasProject && hasProject !== '') ? 'project-contribution' :
          isPayeeIndigenous ? 'community-contribution' : 'donation-contribution';
      default:
        return '';
    }
  }

  private getExpenseKind(expenseClass: string, recordExpenseKind: string ) {
    switch (expenseClass) {
      case 'services':
      case 'goods':
      case 'equipment':
        return 'cash';
      case 'contribution':
        return (recordExpenseKind && recordExpenseKind !== '') ? recordExpenseKind : 'cash';
      default:
        return 'cash';
    }
  }

  private isPayeeIndigenousCommunity(hasPayee: ShortRecord|null): boolean {
    return (this.helper.ics && hasPayee) ?
      this.helper.ics.find((i) => i.identifier === hasPayee.identifier) !== undefined ?
        true : false : false;
  }
}
